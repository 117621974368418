<template>
    <div>
        <v-layout column class="mb-3">
            <h4 class="font-weight-regular">
                {{ $t("applications.debit-or-credit-card") }}
                <v-icon size="10px" color="error" class="mb-1"
                    >brightness_1</v-icon
                >
            </h4>
            <v-card elevation-10 class="my-2">
                <div id="card-element" class="my-3 px-3" color="accent">
                    <!-- A Stripe Element will be inserted here. -->
                </div>
            </v-card>
            <!-- Used to display Element errors. -->
            <div id="card-errors" class="mt-2 red--text" role="alert"></div>
        </v-layout>
    </div>
</template>
<script>
/* eslint-enable no-undef */
export default {
    name: "form-credit-card",
    components: {},
    props: {
        iconColor: {
            type: String,
            required: false,
        },
        stripeKey: {
            type: String,
            required: true,
        },
    },
    computed: {},
    data() {
        return {
            card: null,
            stripe: null,
        };
    },
    mounted() {
        this.initializeStripe();
        this.mountStripe();
    },
    methods: {
        async createToken() {
            let response = {
                token: {},
                error: "",
            };
            const result = await this.stripe.createToken(this.card);
            if (result.error) {
                let displayError = document.getElementById("card-errors");
                displayError.textContent = result.error.message;
                response.error = result.error.message;
            } else {
                response.token = result.token;
            }
            return response;
        },
        initializeStripe() {
            /* eslint-disable no-undef   */
            this.stripe = Stripe(this.stripeKey);
            /* eslint-enable   */
            // Create an instance of Elements.
            let elements = this.stripe.elements({
                fonts: [
                    {
                        cssSrc:
                            "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700",
                    },
                ],
            });

            const style = {
                base: {
                    // Add your base input styles here. For example:
                    fontSize: "16px",
                    color: "#32325d",
                    iconColor: this.iconColor,
                    fontFamily: "Roboto",
                    padding: "5px",
                },
            };
            // Create an instance of the card Element.
            /* eslint-disable no-undef   */
            this.card = elements.create("card", { style: style });
        },
        mountStripe() {
            let _self = this;

            // Add an instance of the card Element into the `card-element` <div>.
            this.card.mount("#card-element");

            this.card.addEventListener("ready", () => {
                _self.card.focus();
            });

            this.card.addEventListener("change", function (event) {
                var displayError = document.getElementById("card-errors");
                if (event.error) {
                    displayError.textContent = event.error.message;
                    _self.$emit("card:input", false);
                } else {
                    displayError.textContent = "";
                    _self.$emit("card:input", true);
                }
            });
        },
    },
};
</script>

<style scoped>
</style>